import { Center, Flex } from "@chakra-ui/react";
import { ReactComponent as Logo } from "media/logo.svg";
import { useHistory } from "react-router-dom";

export default function ContainerAuth({ children }) {
  const history = useHistory();
  return (
    <Center
      bg="rgb(176, 224, 251)"
      height="100vh"
      width="100vw"
      overflow="auto"
    >
      <Flex
        bg="white"
        p={12}
        direction="column"
        alignItems="center"
        minWidth={400}
        maxWidth={500}
        gap={4}
        boxShadow="#9cb5d3 1px 1px 11px 0px"
      >
        <Flex mb={8} onClick={() => history.push("/")} cursor="pointer">
          <Logo />
        </Flex>
        {children}
      </Flex>
    </Center>
  );
}
