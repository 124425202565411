import React, { useMemo } from "react";
import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  IconButton,
} from "@chakra-ui/react";

import * as ROUTES from "../constants/Routes";
import { Link, useHistory } from "react-router-dom";

import UserLogout, { googleLogout } from "../service/Logout";

import { ChevronDownIcon, QuestionIcon, SettingsIcon } from "@chakra-ui/icons";

import tutoriaLogo from "../media/tutoria-logo.png";
import { fetchUserData } from "utils/user";
import { useTranslation } from "react-i18next";
import ChangeLang from "./ChangeLang";
import { useQuery } from "utils/useQuery";

const NavBar = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQuery();
  const hasParams = useMemo(() => {
    return query.get("token") && query.get("action");
  }, [query]);

  return (
    <Flex
      w="100vw"
      top={0}
      h="55px"
      p="6"
      alignItems="center"
      justifyContent="space-between"
      marginBottom="6"
      position="fixed"
      zIndex="999"
      backgroundColor="#F7F7FE"
      boxShadow="2px 1px 3px 1px #e2e8f0"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Link to={"/"}>
          <Image w="100px" ml="16" src={tutoriaLogo} alt="Tutoria Logo" />
        </Link>
        <Flex marginX="12">
          {!query.get("work_id") && (
            <Button
              marginX="2"
              onClick={() => history.push(ROUTES.HOME)}
              colorScheme="blue"
              variant="ghost"
            >
              {t("MY_CLASSES")}
            </Button>
          )}

          <Button
            marginX="2"
            onClick={() => history.push(ROUTES.PRIVACY_POLICY)}
            colorScheme="blue"
            variant="ghost"
          >
            {t("PRIVACY_POLICY")}
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <IconButton
          colorScheme="blue"
          variant="ghost"
          onClick={() => history.push(ROUTES.HELP)}
          icon={<QuestionIcon />}
          isRound={true}
          mr={1}
        />
        <IconButton
          colorScheme="blue"
          variant="ghost"
          onClick={() => history.push(ROUTES.SETTINGS)}
          icon={<SettingsIcon />}
          isRound={true}
          mr={1}
        />
        <ChangeLang />
        {!hasParams && (
          <Menu>
            <MenuButton
              as={Button}
              backgroundColor="#F7F7FE"
              alignItems="center"
              justifyContent="space-around"
              rightIcon={<ChevronDownIcon />}
              p={2}
            >
              <Flex alignItems="center">
                <Avatar
                  bgColor="#4E81BD"
                  color="whitesmoke"
                  name={
                    window.localStorage.getItem("currentUser")
                      ? JSON.parse(window.localStorage.getItem("currentUser"))
                          ?.name
                      : ""
                  }
                  size="sm"
                />
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={async () => {
                  const { email } = await fetchUserData();
                  await googleLogout(email);
                  history.push("/");
                  document.location.reload(true);
                }}
              >
                {t("END_GOOGLE_SESSION")}
              </MenuItem>
              <MenuItem onClick={() => UserLogout()}>
                {t("END_TUTORIA_SESSION")}
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
    </Flex>
  );
};

export default NavBar;
